.buecher {
    font-family: 'Neue Haas Unica W1G', sans-serif;
    font-weight: 300;

    &__buch {
        color: black;
        text-decoration: none;
    }

    &__buch-titel,
    &__buch-shorty-titel {
        font-weight: 700;
    }

    &__buch-bild {
        position: relative;
    }

    &__buch-svg {
        position: absolute;
        z-index: 10;
    }

    &__buch-kurzbeschreibung {
        font-weight: 700;

        .body--word & {
            color: #4676C1;
        }

        .body--excel & {
            color: #0F9844;
        }

        .body--powerpoint & {
            color: #EF5A18;
        }

        .body--junior & {
            color: black;
        }

        .body--shorty & {
            color: #E7EF20;
        }
    }
    
    &__shorty-cover {
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        position: relative;
    }
    
    &__shorty-cover-container {
        background-color: #F5FF00;
        border-radius: 100%;
        width: 100%;
        height: 100%;
        position: absolute;
        transform: rotate(340deg);
    }
    
    &__shorty-cover-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: black;
            width: 100%;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .buecher {

        &__buecher {
            @include abstand($layout, margin, alle, 6, 0, 0, 0);
            display: flex;
            flex-wrap: wrap;
        }
        
   
        &__titel {
            @include abstand($layout, padding, bottom, 7);
            @include schriftart($layout, 36, 42);
            border-bottom: groesse($layout, 2) solid black;
            width: 100%;
        }

        &__buch {
            @include abstand($layout, margin, alle, 20, 0, 20, 0);
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        
        &__buch-bild {
            @include abstand($layout, margin, top, 6);
            width: spalten($layout, 1, 2, 0, 0);
        }

        &__buch-svg {
            width: groesse($layout, 52);
            height: groesse($layout, 52);
            transform: rotate(-20deg);
            top: groesse($layout, 7);
            right: groesse($layout, 7);
        }
        
        &__beschreibung {
            width: spalten($layout, 1, 2, 0, 0);
            @include schriftart($layout, 18, 23);
        }
        
        &__buch-shorty-titel {
            @include abstand($layout, margin, bottom, 7);
        }
        
        &__buch-kurzbeschreibung {
            @include abstand($layout, margin, alle, 7, 0, 7, 0);
        }
        
        &__info {
            @include abstand($layout, margin, top, 20);
            @include schriftart($layout, 18, 23);
        }

        &__shorty-cover-text {
            @include schriftart($layout, 28, 40, bold);
            
            &::after {
                height: groesse($layout, 4);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .buecher {
        
        &__buecher {
            @include abstand($layout, margin, alle, 0, -25, 0, -25);
            display: flex;
            flex-wrap: wrap;
        }
        
        &__titel {
            @include abstand($layout, padding, bottom, 26);
            @include schriftart($layout, 60, 64);
            border-bottom: groesse($layout, 3) solid black;
            width: 100%;
        }

        &__buch {
            @include abstand($layout, margin, alle, 44, 25, 44, 25);
            @include schriftart($layout, 22, 28);
            width: spalten($layout, 1, 4, 1, 0);
            overflow: hidden;
            position: relative;

            &:hover {
                .buecher__buch-bild::before {
                    opacity: 1;
                    
                    .buecher--shorty & {
                        opacity: 0;
                    }
                }

            }
        }

        &__buch-bild {
            overflow: hidden;
            @include abstand($layout, margin, bottom, 20);
            transform: scale(1); 
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(100, 89, 91, 0.7);
                opacity: 0;
                transition: opacity 0.3s ease;
                z-index: 100;
            }
        }

        &__buch-svg {
            width: groesse($layout, 75);
            height: groesse($layout, 75);
            transform: rotate(-20deg);
            right: groesse($layout, 15);
            top: groesse($layout, 15);
        }
        
        &__buch-shorty-titel {
            @include abstand($layout, margin, bottom, 10);
        }

        &__buch-kurzbeschreibung {
            @include abstand($layout, margin, bottom, 10);
        }

        &__info {
            @include abstand($layout, margin, alle, 0, 25, 0, 25);
            @include abstand($layout, padding, top, 44);
            @include schriftart($layout, 22, 28);
        }

        &__shorty-cover-text {
            @include schriftart($layout, 44, 64, bold);
            
            &::after {
                height: groesse($layout, 6);
            }
        }
    }
}