.bild {
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:after, *:before {
  box-sizing: border-box;
}

body {
  background-color: white;
  margin: 0 auto;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: scroll;
  text-decoration-skip-ink: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-synthesis: none;
}
body[data-menue-aktiv=true] {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

#seite:after {
  background-color: white;
  content: "";
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -100;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
  position: relative;
}
ul li::before {
  content: "-";
  left: 0;
  position: absolute;
}

sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

svg {
  padding: 1px 1px 1px 1px;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

*:focus-visible {
  outline: 2px solid black;
}

.bild-verhaeltnis {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
}
.bild-verhaeltnis.lazyloaded {
  background-color: white;
  transition: background-color 500ms ease-in;
}

.bild-verhaeltnis img,
.bild-verhaeltnis picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

img {
  color: transparent;
  display: block;
  line-height: 1em;
  text-indent: -9999px;
}

.agb {
  font-family: "Neue Haas Unica W1G", sans-serif;
  background-color: #282828;
  color: white;
  min-height: 100vh;
}
.agb__agb p {
  font-weight: 300;
}
.agb__agb ul {
  font-weight: 300;
}
.agb__agb strong {
  font-weight: 700;
}
.agb__agb a {
  color: white;
}
.agb__download {
  appearance: none;
  background-color: white;
  border: none;
  color: black;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 699px) {
  .agb {
    margin: 0vw 0vw -52.56vw 0vw;
  }
  .agb__container {
    padding: 5.64vw 7.69vw 46.92vw 7.69vw;
  }
  .agb__titel {
    padding-bottom: 2.56vw;
    font-size: 9.23vw;
    line-height: 10.26vw;
    font-weight: 300;
    border-bottom: 0.51vw solid white;
  }
  .agb__titel--desktop {
    display: none;
  }
  .agb__titel--smartphone {
    display: block;
  }
  .agb__agb {
    padding: 5.64vw 0vw 5.64vw 0vw;
    font-size: 4.62vw;
    line-height: 5.9vw;
    font-weight: 300;
  }
  .agb__agb ul li {
    padding-left: 3.33vw;
  }
  .agb__agb ol {
    padding-left: 5.13vw;
  }
  .agb__agb p {
    margin: 8.97vw 0vw 8.97vw 0vw;
  }
  .agb__agb *:first-child {
    margin-top: 0vw;
  }
  .agb__agb *:last-child {
    margin-bottom: 0vw;
  }
  .agb__download {
    margin-top: 16.67vw;
    padding: 0vw 2.56vw 0vw 2.56vw;
    font-size: 4.62vw;
    line-height: 4.62vw;
    font-weight: 700;
    align-items: center;
    display: inline-flex;
    height: 7.69vw;
  }
}
@media (min-width: 700px) {
  .agb {
    margin: -1.98vw -2.6vw -7.55vw -2.6vw;
  }
  .agb__container {
    padding: 2.34vw 2.6vw 5.21vw 2.6vw;
  }
  .agb__titel {
    padding-bottom: 1.35vw;
    font-size: 3.13vw;
    line-height: 2.08vw;
    font-weight: 300;
    border-bottom: 0.16vw solid white;
  }
  .agb__titel--desktop {
    display: block;
  }
  .agb__titel--smartphone {
    display: none;
  }
  .agb__agb {
    padding-top: 2.5vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
    width: calc((100% - 0vw - 7.8vw) / 4 * 2 + 2.6vw + 0vw);
  }
  .agb__agb ul {
    margin: 1.82vw 0vw 1.82vw 0vw;
  }
  .agb__agb ul li {
    padding-left: 0.68vw;
  }
  .agb__agb ol {
    margin: 1.82vw 0vw 1.82vw 0vw;
    padding-left: 1.2vw;
  }
  .agb__agb p {
    margin: 1.82vw 0vw 1.82vw 0vw;
  }
  .agb__agb *:first-child {
    margin-top: 0vw;
  }
  .agb__agb *:last-child {
    margin-bottom: 0vw;
  }
  .agb__download {
    margin-top: 3.39vw;
    padding: 0vw 0.52vw 0vw 0.52vw;
    font-size: 1.15vw;
    line-height: 1.15vw;
    font-weight: 700;
    height: 2.08vw;
    display: inline-flex;
    align-items: center;
  }
}
.benutzerprofil {
  font-family: "Neue Haas Unica W1G", sans-serif;
  background-color: #282828;
  color: white;
  min-height: 100vh;
}

@media (max-width: 699px) {
  .benutzerprofil {
    margin: 0vw 0vw -52.56vw 0vw;
  }
  .benutzerprofil__container {
    padding: 5.64vw 7.69vw 46.92vw 7.69vw;
  }
  .benutzerprofil__titel {
    padding-bottom: 2.56vw;
    font-size: 9.23vw;
    line-height: 10.26vw;
    font-weight: 300;
    border-bottom: 0.51vw solid white;
  }
  .benutzerprofil__titel--desktop {
    display: none;
  }
  .benutzerprofil__titel--smartphone {
    display: block;
  }
}
@media (min-width: 700px) {
  .benutzerprofil {
    margin: -1.98vw -2.6vw -7.55vw -2.6vw;
  }
  .benutzerprofil__container {
    padding: 2.34vw 2.6vw 5.21vw 2.6vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .benutzerprofil__titel {
    padding-bottom: 1.35vw;
    font-size: 3.13vw;
    line-height: 2.08vw;
    font-weight: 300;
    border-bottom: 0.16vw solid white;
    width: 100%;
  }
  .benutzerprofil__profil {
    padding-top: 2.5vw;
    width: 100%;
  }
}
.bold-list::marker, .bold-list::before {
  font-weight: 700;
}

.buch {
  font-family: "Neue Haas Unica W1G", sans-serif;
  font-weight: 300;
}
.buch__kategorie {
  font-weight: 300;
}
.buch__beschreibung {
  font-weight: 700;
}
.buch__bild {
  position: relative;
}
.buch__buch-svg {
  position: absolute;
  z-index: 10;
}
.buch__buttons--2 {
  display: flex;
  justify-content: flex-end;
}
.buch__button {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  color: white;
  background-color: black;
  text-decoration: none;
  font-weight: 700;
  display: inline-block;
}
.buch__button--warenkorb {
  cursor: pointer;
}
.body--word-entry .buch__button--warenkorb {
  background-color: #4676C1;
}
.body--excel-entry .buch__button--warenkorb {
  background-color: #0F9844;
}
.body--powerpoint-entry .buch__button--warenkorb {
  background-color: #EF5A18;
}
.body--junior-entry .buch__button--warenkorb {
  background-color: #7EF848;
  color: #000;
}
.body--shorty-entry .buch__button--warenkorb {
  color: #000;
  background-color: #E7EF20;
}
.buch__button--download {
  color: black;
  font-weight: 700;
}
.body--word-entry .buch__button--download {
  background-color: #4676C1;
}
.body--excel-entry .buch__button--download {
  background-color: #0F9844;
}
.body--powerpoint-entry .buch__button--download {
  background-color: #EF5A18;
}
.body--junior-entry .buch__button--download {
  background-color: #7EF848;
}
.body--shorty-entry .buch__button--download {
  background-color: #F5FF00;
}
.body--word-entry .buch__kurzbeschreibung {
  color: #4676C1;
}
.body--excel-entry .buch__kurzbeschreibung {
  color: #0F9844;
}
.body--powerpoint-entry .buch__kurzbeschreibung {
  color: #EF5A18;
}
.body--junior-entry .buch__kurzbeschreibung {
  color: #7EF848;
}
.body--shorty-entry .buch__kurzbeschreibung {
  color: #E7EF20;
}
.buch__weitere-lehrmittel {
  display: flex;
  flex-direction: column;
}
.buch__weitere-lehrmittel-titel {
  font-weight: 700;
}
.buch__weitere-lehrmittel-titel {
  text-decoration: underline;
  color: #000;
}
.buch__details strong {
  font-weight: 700;
}
.buch__shorty-cover {
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.buch__shorty-cover-container {
  background-color: #F5FF00;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(340deg);
}
.buch__shorty-cover-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.buch__shorty-cover-text::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  width: 100%;
}

@media (max-width: 699px) {
  .buch__einleitung--desktop {
    display: none;
  }
  .buch__einleitung--smartphone {
    display: block;
  }
  .buch__titel {
    font-size: 5.64vw;
    line-height: 6.92vw;
  }
  .buch__kurzbeschreibung {
    font-size: 5.64vw;
    line-height: 6.92vw;
  }
  .buch__bild {
    margin-bottom: 4.62vw;
  }
  .buch__buch-svg {
    width: 23.08vw;
    height: 23.08vw;
    transform: rotate(-20deg);
    top: 6.41vw;
    right: 6.41vw;
  }
  .buch__buttons--1-desktop {
    display: none;
  }
  .buch__buttons--1-smartphone {
    display: block;
  }
  .buch__button {
    margin: 1.03vw 1.03vw 1.03vw 1.03vw;
    padding: 0vw 2.56vw 0vw 2.56vw;
    font-size: 4.62vw;
    line-height: 5.9vw;
    height: 7.69vw;
    display: inline-flex;
    align-items: center;
  }
  .buch__kategorie {
    padding-bottom: 3.08vw;
    margin-bottom: 7.95vw;
    font-size: 5.64vw;
    line-height: 6.92vw;
  }
  .buch__beschreibung {
    margin: 13.85vw 0vw 10.77vw 0vw;
    font-size: 4.62vw;
    line-height: 5.9vw;
  }
  .buch__details {
    font-size: 3.85vw;
    line-height: 5.13vw;
  }
  .buch__details p {
    margin: 8.97vw 0vw 0vw 0vw;
  }
  .buch__details ul {
    margin: 0vw 0vw 8.97vw 0vw;
  }
  .buch__details ul li {
    padding-left: 3.33vw;
  }
  .buch__details ol {
    margin: 8.97vw 0vw 8.97vw 0vw;
    padding-left: 5.13vw;
  }
  .buch__details *:first-child {
    margin-top: 0vw;
  }
  .buch__details *:last-child {
    margin-bottom: 0vw;
  }
  .buch__weitere-lehrmittel {
    margin-top: 6.15vw;
    font-size: 3.85vw;
    line-height: 5.13vw;
  }
  .buch__kategorie {
    border-bottom: 0.51vw solid black;
  }
  .buch__shorty-cover-text {
    font-size: 16.15vw;
    line-height: 23.08vw;
    font-weight: bold;
  }
  .buch__shorty-cover-text::after {
    height: 2.31vw;
  }
}
@media (min-width: 700px) {
  .buch {
    margin-top: 6.72vw;
    display: flex;
    justify-content: space-between;
  }
  .buch__einleitung--desktop {
    display: block;
  }
  .buch__einleitung--smartphone {
    display: none;
  }
  .buch__container--1 {
    width: 26.04vw;
  }
  .buch__container--2 {
    width: calc((100% - 0vw - 7.8vw) / 4 * 2 + 3.64vw + 0vw);
  }
  .buch__sticky-container {
    position: sticky;
    top: 6.72vw;
  }
  .buch__bild {
    margin-bottom: 2.08vw;
  }
  .buch--shorty .buch__bild {
    padding-left: 50%;
  }
  .buch__buch-svg {
    width: 7.03vw;
    height: 7.03vw;
    transform: rotate(-20deg);
    right: 2.08vw;
    top: 2.08vw;
  }
  .buch__buttons--1-desktop {
    display: flex;
    justify-content: space-between;
  }
  .buch__buttons--1-smartphone {
    display: none;
  }
  .buch__button {
    padding: 0vw 0.52vw 0vw 0.52vw;
    font-size: 0.94vw;
    line-height: 1.46vw;
    display: flex;
    align-items: center;
    height: 2.08vw;
  }
  .buch__titel {
    font-size: 1.88vw;
    line-height: 2.19vw;
    font-weight: 700;
  }
  .buch__kurzbeschreibung {
    font-size: 1.88vw;
    line-height: 2.19vw;
    font-weight: 700;
  }
  .buch__kategorie {
    padding-bottom: 0.94vw;
    margin-bottom: 1.51vw;
    font-size: 1.88vw;
    line-height: 2.19vw;
    border-bottom: 0.16vw solid black;
  }
  .buch__beschreibung {
    margin-bottom: 0.94vw;
    font-size: 1.15vw;
    line-height: 1.41vw;
  }
  .buch__details {
    font-size: 0.94vw;
    line-height: 1.2vw;
  }
  .buch__details p {
    margin: 1.82vw 0vw 0vw 0vw;
  }
  .buch__details ul {
    margin: 0vw 0vw 1.82vw 0vw;
  }
  .buch__details ul li {
    padding-left: 0.68vw;
  }
  .buch__details ol {
    padding-left: 1.2vw;
  }
  .buch__details *:first-child {
    margin-top: 0vw;
  }
  .buch__details *:last-child {
    margin-bottom: 0vw;
  }
  .buch__weitere-lehrmittel {
    margin-top: 1.56vw;
    font-size: 0.94vw;
    line-height: 1.2vw;
  }
  .buch__shorty-cover-text {
    font-size: 2.29vw;
    line-height: 3.33vw;
    font-weight: bold;
  }
  .buch__shorty-cover-text::after {
    height: 0.31vw;
  }
}
.buecher {
  font-family: "Neue Haas Unica W1G", sans-serif;
  font-weight: 300;
}
.buecher__buch {
  color: black;
  text-decoration: none;
}
.buecher__buch-titel, .buecher__buch-shorty-titel {
  font-weight: 700;
}
.buecher__buch-bild {
  position: relative;
}
.buecher__buch-svg {
  position: absolute;
  z-index: 10;
}
.buecher__buch-kurzbeschreibung {
  font-weight: 700;
}
.body--word .buecher__buch-kurzbeschreibung {
  color: #4676C1;
}
.body--excel .buecher__buch-kurzbeschreibung {
  color: #0F9844;
}
.body--powerpoint .buecher__buch-kurzbeschreibung {
  color: #EF5A18;
}
.body--junior .buecher__buch-kurzbeschreibung {
  color: black;
}
.body--shorty .buecher__buch-kurzbeschreibung {
  color: #E7EF20;
}
.buecher__shorty-cover {
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.buecher__shorty-cover-container {
  background-color: #F5FF00;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(340deg);
}
.buecher__shorty-cover-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.buecher__shorty-cover-text::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  width: 100%;
}

@media (max-width: 699px) {
  .buecher__buecher {
    margin: 1.54vw 0vw 0vw 0vw;
    display: flex;
    flex-wrap: wrap;
  }
  .buecher__titel {
    padding-bottom: 1.79vw;
    font-size: 9.23vw;
    line-height: 10.77vw;
    border-bottom: 0.51vw solid black;
    width: 100%;
  }
  .buecher__buch {
    margin: 5.13vw 0vw 5.13vw 0vw;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .buecher__buch-bild {
    margin-top: 1.54vw;
    width: calc((100% - 0vw - 7.69vw) / 2 * 1 + 0vw + 0vw);
  }
  .buecher__buch-svg {
    width: 13.33vw;
    height: 13.33vw;
    transform: rotate(-20deg);
    top: 1.79vw;
    right: 1.79vw;
  }
  .buecher__beschreibung {
    width: calc((100% - 0vw - 7.69vw) / 2 * 1 + 0vw + 0vw);
    font-size: 4.62vw;
    line-height: 5.9vw;
  }
  .buecher__buch-shorty-titel {
    margin-bottom: 1.79vw;
  }
  .buecher__buch-kurzbeschreibung {
    margin: 1.79vw 0vw 1.79vw 0vw;
  }
  .buecher__info {
    margin-top: 5.13vw;
    font-size: 4.62vw;
    line-height: 5.9vw;
  }
  .buecher__shorty-cover-text {
    font-size: 7.18vw;
    line-height: 10.26vw;
    font-weight: bold;
  }
  .buecher__shorty-cover-text::after {
    height: 1.03vw;
  }
}
@media (min-width: 700px) {
  .buecher__buecher {
    margin: 0vw -1.3vw 0vw -1.3vw;
    display: flex;
    flex-wrap: wrap;
  }
  .buecher__titel {
    padding-bottom: 1.35vw;
    font-size: 3.13vw;
    line-height: 3.33vw;
    border-bottom: 0.16vw solid black;
    width: 100%;
  }
  .buecher__buch {
    margin: 2.29vw 1.3vw 2.29vw 1.3vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
    width: calc((100% - 0vw - 10.4vw) / 4 * 1 + 0vw + 0vw);
    overflow: hidden;
    position: relative;
  }
  .buecher__buch:hover .buecher__buch-bild::before {
    opacity: 1;
  }
  .buecher--shorty .buecher__buch:hover .buecher__buch-bild::before {
    opacity: 0;
  }
  .buecher__buch-bild {
    overflow: hidden;
    margin-bottom: 1.04vw;
    transform: scale(1);
    position: relative;
  }
  .buecher__buch-bild::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(100, 89, 91, 0.7);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 100;
  }
  .buecher__buch-svg {
    width: 3.91vw;
    height: 3.91vw;
    transform: rotate(-20deg);
    right: 0.78vw;
    top: 0.78vw;
  }
  .buecher__buch-shorty-titel {
    margin-bottom: 0.52vw;
  }
  .buecher__buch-kurzbeschreibung {
    margin-bottom: 0.52vw;
  }
  .buecher__info {
    margin: 0vw 1.3vw 0vw 1.3vw;
    padding-top: 2.29vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
  }
  .buecher__shorty-cover-text {
    font-size: 2.29vw;
    line-height: 3.33vw;
    font-weight: bold;
  }
  .buecher__shorty-cover-text::after {
    height: 0.31vw;
  }
}
.datenschutz-impressum {
  font-family: "Neue Haas Unica W1G", sans-serif;
  background-color: #282828;
  color: white;
  min-height: 100vh;
}
.datenschutz-impressum__inhalt a {
  color: white;
}
.datenschutz-impressum__inhalt p {
  font-weight: 300;
}
.datenschutz-impressum__inhalt strong {
  font-weight: 700;
}

@media (max-width: 699px) {
  .datenschutz-impressum {
    margin: 0vw 0vw -52.56vw 0vw;
  }
  .datenschutz-impressum__container {
    padding: 5.64vw 7.69vw 46.92vw 7.69vw;
    word-wrap: break-word;
  }
  .datenschutz-impressum__titel {
    padding: 0vw 0vw 2.56vw 0vw;
    font-size: 9.23vw;
    line-height: 10.26vw;
    font-weight: 300;
    border-bottom: 0.51vw solid white;
  }
  .datenschutz-impressum__ueberschrift {
    padding-bottom: 2.05vw;
    font-size: 4.62vw;
    line-height: 5.9vw;
  }
  .datenschutz-impressum__inhalt {
    font-size: 4.62vw;
    line-height: 5.9vw;
    padding-top: 5.64vw;
  }
  .datenschutz-impressum__inhalt p {
    margin: 8.97vw 0vw 8.97vw 0vw;
  }
  .datenschutz-impressum__inhalt ul {
    margin: 8.97vw 0vw 8.97vw 0vw;
  }
  .datenschutz-impressum__inhalt ul li {
    padding-left: 3.33vw;
  }
  .datenschutz-impressum__inhalt ol {
    margin: 8.97vw 0vw 8.97vw 0vw;
    padding-left: 5.13vw;
  }
  .datenschutz-impressum__inhalt *:first-child {
    margin-top: 0vw;
  }
  .datenschutz-impressum__inhalt *:last-child {
    margin-bottom: 0vw;
  }
}
@media (min-width: 700px) {
  .datenschutz-impressum {
    margin: -1.98vw -2.6vw -7.55vw -2.6vw;
  }
  .datenschutz-impressum__container {
    padding: 2.34vw 2.6vw 5.21vw 2.6vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .datenschutz-impressum__titel {
    padding-bottom: 1.35vw;
    font-size: 3.13vw;
    line-height: 2.08vw;
    font-weight: 300;
    border-bottom: 0.16vw solid white;
    width: 100%;
  }
  .datenschutz-impressum__ueberschrift {
    font-size: 1.15vw;
    line-height: 1.46vw;
  }
  .datenschutz-impressum__inhalt {
    padding-top: 2.5vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
    width: calc((100% - 0vw - 7.8vw) / 4 * 2 + 2.6vw + 0vw);
  }
  .datenschutz-impressum__inhalt p {
    margin: 1.82vw 0vw 1.82vw 0vw;
  }
  .datenschutz-impressum__inhalt ul {
    margin: 1.82vw 0vw 1.82vw 0vw;
  }
  .datenschutz-impressum__inhalt ul li {
    padding-left: 0.68vw;
  }
  .datenschutz-impressum__inhalt ol {
    padding-left: 1.2vw;
  }
  .datenschutz-impressum__inhalt *:first-child {
    margin-top: 0vw;
  }
  .datenschutz-impressum__inhalt *:last-child {
    margin-bottom: 0vw;
  }
}
.fui-i {
  font-family: "Neue Haas Unica W1G", sans-serif;
}
.fui-i a {
  color: #fff;
}
.fui-i a {
  color: #fff;
}
.fui-i .fui-radio-label,
.fui-i .fui-label,
.fui-i .fui-checkbox-label {
  color: #fff;
}
.fui-i .fui-input[name="fields[institution]"],
.fui-i .fui-input[name="fields[vorname][firstName]"],
.fui-i .fui-input[name="fields[nachname]"],
.fui-i .fui-input[name="fields[adresse][address1]"],
.fui-i .fui-input[name="fields[plz]"],
.fui-i .fui-input[name="fields[ort]"],
.fui-i .fui-input[name="fields[telefonnummer][number]"],
.fui-i .fui-input[name="fields[eMail]"] {
  font-weight: 700;
  border-radius: 0;
}
.fui-i .fui-submit {
  appearance: none;
  background-color: white;
  border: none;
  color: black;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0;
}
.fui-i .fui-checkbox label::before, .fui-i .fui-radio label::before {
  background-color: #fff;
  border: 4px solid #fff;
}
.fui-i .fui-checkbox input:checked + label::before, .fui-i .fui-radio input:checked + label::before {
  background-color: black;
}

@media (max-width: 699px) {
  .fui-i .fui-layout-horizontal .fui-layout-wrap {
    margin-bottom: 5.9vw;
    display: flex;
    flex-direction: column;
  }
  .fui-i .fui-field:not(.fui-type-hidden-field) {
    margin-bottom: 2.56vw;
  }
  .fui-i .fui-radio-label,
  .fui-i .fui-label,
  .fui-i .fui-checkbox-label {
    font-size: 4.62vw;
  }
  .fui-i .fui-field-container {
    position: relative;
  }
  .fui-i .form-floating > .form-floating-control::placeholder {
    color: transparent;
  }
  .fui-i .fui-input {
    padding: 5.13vw 2.56vw 1.28vw 2.56vw;
    font-size: 4.62vw;
    line-height: 3.59vw;
    font-weight: 700;
    width: 100%;
    border: 0;
    background-color: white;
    border-bottom: 1px solid #000;
    border-radius: 0;
    cursor: text;
    -webkit-appearance: none;
    color: #000;
  }
  .fui-i .form-floating {
    position: relative;
  }
  .fui-i .form-floating > label {
    font-size: 4.62vw;
    line-height: 3.59vw;
    width: 100%;
    bottom: 6.41vw;
    left: 2.56vw;
    position: absolute;
    transition: all 0.2s;
    touch-action: manipulation;
    z-index: 1;
    color: #000;
    pointer-events: none;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }
  .fui-i .form-floating > .form-floating-control:focus ~ label,
  .fui-i .form-floating > .form-floating-control:not(:placeholder-shown) ~ label {
    opacity: 1;
    transform: scale(0.63);
    color: #000;
  }
  .fui-i .form-floating > .form-floating-control:-webkit-autofill ~ label {
    opacity: 1;
    transform: scale(0.63);
    color: #000;
  }
  .fui-i .fui-label {
    font-size: 4.62vw;
    line-height: 3.59vw;
    width: 100%;
    bottom: 6.41vw;
    left: 2.56vw;
    position: absolute;
    transition: all 0.2s;
    touch-action: manipulation;
    z-index: 1;
    color: #000;
    font-weight: 300;
  }
  .fui-i .fui-type-agree {
    padding-top: 7.69vw;
  }
  .fui-i .fui-btn-wrapper {
    padding-top: 8.97vw;
  }
  .fui-i .fui-submit {
    padding: 0vw 2.56vw 0vw 2.56vw;
    font-size: 4.62vw;
    line-height: 4.62vw;
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 7.69vw;
  }
  .fui-i .fui-checkbox input:checked + label::before, .fui-i .fui-radio input:checked + label::before {
    background-color: black;
    border: 0.51vw solid white;
  }
}
@media (min-width: 700px) {
  .fui-i .fui-field:not(.fui-type-hidden-field) {
    margin-bottom: 0.52vw;
  }
  .fui-i .fui-radio-label,
  .fui-i .fui-label,
  .fui-i .fui-checkbox-label {
    font-size: 1.15vw;
  }
  .fui-i .fui-field-container {
    position: relative;
  }
  .fui-i .form-floating > .form-floating-control::placeholder {
    color: transparent;
  }
  .fui-i .fui-input {
    padding: 1.04vw 0.52vw 0.26vw 0.52vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
    font-weight: 700;
    width: calc((100% - 0vw - 0vw) / 2 * 1 + 0vw + 0vw);
    border: 0;
    background-color: white;
    border-bottom: 1px solid #000;
    border-radius: 0;
    cursor: text;
    -webkit-appearance: none;
    color: #000;
  }
  .fui-i .form-floating {
    position: relative;
  }
  .fui-i .form-floating > label {
    font-size: 1.15vw;
    line-height: 1.46vw;
    width: 100%;
    bottom: 1.3vw;
    left: 0.52vw;
    position: absolute;
    transition: all 0.2s;
    touch-action: manipulation;
    z-index: 1;
    color: #000;
    pointer-events: none;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }
  .fui-i .form-floating > .form-floating-control:focus ~ label,
  .fui-i .form-floating > .form-floating-control:not(:placeholder-shown) ~ label {
    opacity: 1;
    transform: scale(0.63);
    color: #000;
  }
  .fui-i .form-floating > .form-floating-control:-webkit-autofill ~ label {
    opacity: 1;
    transform: scale(0.63);
    color: #000;
  }
  .fui-i .fui-type-agree {
    padding-top: 1.56vw;
  }
  .fui-i .fui-btn-wrapper {
    padding-top: 1.82vw;
  }
  .fui-i .fui-label {
    font-size: 1.15vw;
    line-height: 1.46vw;
    width: 100%;
    bottom: 1.3vw;
    left: 0.52vw;
    position: absolute;
    transition: all 0.2s;
    touch-action: manipulation;
    z-index: 1;
    color: #000;
    font-weight: 300;
  }
  .fui-i .fui-submit {
    padding: 0vw 0.52vw 0vw 0.52vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 2.08vw;
  }
  .fui-i .fui-submit:hover {
    background-color: white;
    border: none;
    color: black;
  }
  .fui-i .fui-checkbox input:checked + label::before, .fui-i .fui-radio input:checked + label::before {
    background-color: black;
    border: 0.16vw solid white;
  }
}
@media (max-width: 699px) {
  .body[data-menue-aktiv=true] .header {
    background-color: white;
    height: 100%;
    left: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 0;
    width: 100vw;
    z-index: 1000;
  }
}
.introtext {
  font-family: "Neue Haas Unica W1G", sans-serif;
}
.introtext a {
  text-decoration: underline;
  color: #000;
}
.introtext *:first-child {
  margin-top: 0;
}
.introtext *:last-child {
  margin-bottom: 0;
}
.introtext__beschreibung {
  font-family: "Neue Haas Unica W1G", sans-serif;
  font-weight: 300;
}

@media (max-width: 699px) {
  .introtext {
    margin-bottom: 5.9vw;
    font-size: 5.64vw;
    line-height: 7.18vw;
    font-weight: 300;
  }
  .introtext p {
    margin: 8.97vw 0vw 8.97vw 0vw;
  }
  .introtext ul {
    margin: 8.97vw 0vw 8.97vw 0vw;
  }
  .introtext ul li {
    padding-left: 3.33vw;
  }
  .introtext ol {
    margin: 8.97vw 0vw 8.97vw 0vw;
    padding-left: 5.13vw;
  }
  .introtext__beschreibung {
    font-size: 4.62vw;
    line-height: 5.9vw;
    margin-bottom: 5.9vw;
  }
  .introtext__beschreibung p {
    margin: 5.9vw 0vw 5.9vw 0vw;
  }
}
@media (min-width: 700px) {
  .introtext {
    margin-bottom: 2.5vw;
    font-size: 1.88vw;
    line-height: 2.19vw;
    font-weight: 300;
    width: calc((100% - 0vw - 7.8vw) / 4 * 3.5 + 7.8vw + 0vw);
  }
  .introtext p {
    margin: 1.82vw 0vw 1.82vw 0vw;
  }
  .introtext ul {
    margin: 1.82vw 0vw 1.82vw 0vw;
  }
  .introtext ul li {
    padding-left: 0.68vw;
  }
  .introtext ol {
    padding-left: 1.2vw;
  }
  .introtext__beschreibung {
    margin-bottom: 2.5vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
  }
  .introtext__beschreibung p {
    margin: 1.46vw 0vw 1.46vw 0vw;
  }
}
.kontakt {
  background-color: #282828;
  color: white;
  font-family: "Neue Haas Unica W1G", sans-serif;
  font-style: normal;
  min-height: 100vh;
}
.kontakt__kontakt {
  font-style: normal;
}
.kontakt__email {
  color: white;
  text-decoration: underline;
  font-weight: 700;
}

@media (max-width: 699px) {
  .kontakt {
    margin: 0vw 0vw -52.56vw 0vw;
  }
  .kontakt__container {
    padding: 5.64vw 7.69vw 46.92vw 7.69vw;
  }
  .kontakt__titel {
    padding-bottom: 2.56vw;
    font-size: 9.23vw;
    line-height: 10.26vw;
    font-weight: 300;
    border-bottom: 0.51vw solid white;
  }
  .kontakt__kontakt {
    padding-top: 5.13vw;
    font-size: 5.64vw;
    line-height: 7.18vw;
    font-weight: 700;
  }
  .kontakt__text {
    font-size: 4.62vw;
    line-height: 5.9vw;
    font-weight: 300;
    margin: 10.26vw 0vw 2.56vw 0vw;
  }
  .kontakt__email {
    font-size: 5.64vw;
    line-height: 7.18vw;
    font-weight: 700;
  }
}
@media (min-width: 700px) {
  .kontakt {
    margin: -1.98vw -2.6vw -7.55vw -2.6vw;
  }
  .kontakt__container {
    padding: 2.34vw 2.6vw 5.21vw 2.6vw;
  }
  .kontakt__titel {
    padding-bottom: 1.35vw;
    font-size: 3.13vw;
    line-height: 2.08vw;
    font-weight: 300;
    border-bottom: 0.16vw solid white;
    width: 100%;
  }
  .kontakt__kontakt {
    padding-top: 2.81vw;
    font-size: 1.88vw;
    line-height: 2.19vw;
    font-weight: 700;
  }
  .kontakt__text {
    font-size: 1.15vw;
    line-height: 1.46vw;
    font-weight: 300;
    margin: 2.08vw 0vw 0.52vw 0vw;
    width: calc((100% - 0vw - 7.8vw) / 4 * 2 + 2.6vw + 0vw);
  }
  .kontakt__email {
    font-size: 1.88vw;
    line-height: 2.19vw;
    font-weight: 700;
  }
}
.login-entry {
  font-family: "Neue Haas Unica W1G", sans-serif;
  background-color: #282828;
  color: #fff;
  min-height: 100vh;
}
.login-entry a {
  text-decoration: underline;
  color: #fff;
}
.login-entry__buch-uebungen--buch {
  appearance: none;
  background-color: white;
  border: none;
  cursor: pointer;
}
.login-entry a.login-entry__buch-uebungen--buch {
  text-decoration: none;
  color: black;
}
.login-entry__buch-titel--beschriftung, .login-entry__buch-kategorie--beschriftung, .login-entry__buch-kaufdatum--beschriftung, .login-entry__buch-e-book--beschriftung, .login-entry__buch-uebungen--beschriftung {
  font-weight: 700;
}
.login-entry__introtext strong {
  font-weight: 700;
}

@media (max-width: 699px) {
  .login-entry {
    margin: 0vw 0vw -52.56vw 0vw;
  }
  .login-entry__container {
    padding: 5.64vw 7.69vw 46.92vw 7.69vw;
  }
  .login-entry__benutzer-buttons {
    margin-bottom: 5.13vw;
    font-size: 4.62vw;
    line-height: 4.62vw;
    font-weight: 300;
    display: flex;
    justify-content: flex-end;
  }
  .login-entry__benutzer-buttons--desktop {
    display: none;
  }
  .login-entry__benutzer-buttons--mobile {
    display: flex;
  }
  .login-entry__titel {
    padding-bottom: 2.56vw;
    font-size: 9.23vw;
    line-height: 10.26vw;
    font-weight: 300;
    border-bottom: 0.51vw solid white;
  }
  .login-entry__titel--desktop {
    display: none;
  }
  .login-entry__titel--smartphone {
    display: block;
  }
  .login-entry__introtext {
    padding: 5.64vw 0vw 9.74vw 0vw;
    font-size: 4.62vw;
    line-height: 5.9vw;
    font-weight: 300;
  }
  .login-entry__introtext ul {
    margin: 8.97vw 0vw 8.97vw 0vw;
  }
  .login-entry__introtext ul li {
    padding-left: 3.33vw;
  }
  .login-entry__untertitel {
    padding-bottom: 7.69vw;
    font-size: 5.64vw;
    line-height: 5.64vw;
    font-weight: 700;
    border-bottom: 1px solid #fff;
  }
  .login-entry__benutzer-container {
    padding-top: 5.13vw;
  }
  .login-entry__benutzer {
    font-size: 4.62vw;
    line-height: 8.21vw;
    font-weight: 300;
  }
  .login-entry__produkt-container {
    margin-top: 30.77vw;
  }
  .login-entry__buch {
    padding: 8.72vw 0vw 8.72vw 0vw;
    border-bottom: 1px solid #fff;
  }
  .login-entry__buch-beschriftung {
    display: none;
  }
  .login-entry__buch-titel--beschriftung, .login-entry__buch-kategorie--beschriftung, .login-entry__buch-kaufdatum--beschriftung, .login-entry__buch-e-book--beschriftung, .login-entry__buch-uebungen--beschriftung {
    font-size: 4.62vw;
    line-height: 6.15vw;
  }
  .login-entry__buch-titel--beschriftung--desktop, .login-entry__buch-kategorie--beschriftung--desktop, .login-entry__buch-kaufdatum--beschriftung--desktop, .login-entry__buch-e-book--beschriftung--desktop, .login-entry__buch-uebungen--beschriftung--desktop {
    display: none;
  }
  .login-entry__buch-titel--beschriftung--mobile, .login-entry__buch-kategorie--beschriftung--mobile, .login-entry__buch-kaufdatum--beschriftung--mobile, .login-entry__buch-e-book--beschriftung--mobile, .login-entry__buch-uebungen--beschriftung--mobile {
    display: block;
  }
  .login-entry__buch-titel--buch, .login-entry__buch-kategorie--buch, .login-entry__buch-kaufdatum--buch, .login-entry__buch-e-book--buch, .login-entry__buch-uebungen--buch {
    margin-bottom: 5.13vw;
    font-size: 4.62vw;
    line-height: 6.15vw;
  }
  .login-entry__buch-container {
    font-size: 4.62vw;
    line-height: 4.62vw;
    font-weight: 700;
    font-weight: 700;
    align-items: center;
    display: flex;
  }
  .login-entry__buch-uebungen--buch {
    padding: 0vw 2.56vw 0vw 2.56vw;
    height: 7.69vw;
    text-align: center;
    display: flex;
    align-items: center;
  }
}
@media (min-width: 700px) {
  .login-entry {
    margin: -1.98vw -2.6vw -7.55vw -2.6vw;
  }
  .login-entry__container {
    padding: 2.34vw 2.6vw 5.21vw 2.6vw;
  }
  .login-entry__titel {
    padding-bottom: 1.35vw;
    font-size: 3.13vw;
    line-height: 2.08vw;
    font-weight: 300;
    border-bottom: 0.16vw solid white;
  }
  .login-entry__titel--desktop {
    display: block;
  }
  .login-entry__titel--smartphone {
    display: none;
  }
  .login-entry__benutzer-container {
    font-size: 1.15vw;
    line-height: 1.15vw;
    padding-top: 2.5vw;
    display: flex;
  }
  .login-entry__benutzer {
    margin-right: auto;
  }
  .login-entry__benutzer-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .login-entry__benutzer-buttons--desktop {
    display: flex;
  }
  .login-entry__benutzer-buttons--mobile {
    display: none;
  }
  .login-entry__buch-uebungen--buch {
    margin-top: -0.39vw;
    padding: 0vw 0.52vw 0vw 0.52vw;
    align-items: center;
    display: flex;
    font-weight: 700;
    height: 2.08vw;
  }
  .login-entry__introtext {
    padding: 2.5vw 0vw 4.38vw 0vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
    font-weight: 300;
  }
  .login-entry__introtext ul {
    margin: 1.82vw 0vw 1.82vw 0vw;
  }
  .login-entry__introtext ul li {
    padding-left: 0.68vw;
  }
  .login-entry__produkt-container {
    padding: 4.38vw 0vw 5.68vw 0vw;
  }
  .login-entry__untertitel {
    margin-bottom: 3.59vw;
    font-size: 1.88vw;
    line-height: 2.08vw;
    font-weight: 700;
  }
  .login-entry__buch-beschriftung-border {
    padding: 0vw 0vw 0.63vw 0vw;
    font-size: 0.94vw;
    line-height: 0.94vw;
    border-bottom: 1px solid #fff;
  }
  .login-entry__buch-beschriftung {
    margin: 0vw -0.42vw 0vw -0.42vw;
    display: flex;
  }
  .login-entry__benutzer {
    font-size: 1.15vw;
    line-height: 1.46vw;
    font-weight: 300;
  }
  .login-entry__buch {
    margin: 0vw -0.42vw 0vw -0.42vw;
    display: flex;
  }
  .login-entry__buch--buch-border {
    padding-bottom: 0.47vw;
    font-size: 1.15vw;
    line-height: 3.13vw;
    border-bottom: 1px solid #fff;
  }
  .login-entry__buch-titel {
    margin: 0vw 0.42vw 0vw 0.42vw;
    width: 20.16vw;
    font-weight: 300;
  }
  .login-entry__buch-titel--beschriftung {
    font-weight: 700;
  }
  .login-entry__buch-titel--beschriftung--desktop {
    display: block;
  }
  .login-entry__buch-titel--beschriftung--mobile {
    display: none;
  }
  .login-entry__buch-kategorie {
    margin: 0vw 0.42vw 0vw 0.42vw;
    width: 8.59vw;
    font-weight: 300;
  }
  .login-entry__buch-kategorie--beschriftung {
    margin: 0vw 0.42vw 0vw 0.42vw;
    font-weight: 700;
  }
  .login-entry__buch-kategorie--beschriftung--desktop {
    display: block;
  }
  .login-entry__buch-kategorie--beschriftung--mobile {
    display: none;
  }
  .login-entry__buch-kaufdatum {
    width: 8.59vw;
    font-weight: 300;
  }
  .login-entry__buch-kaufdatum--buch {
    margin: 0vw 0.42vw 0vw 0.42vw;
  }
  .login-entry__buch-kaufdatum--beschriftung {
    margin: 0vw 0.42vw 0vw 0.42vw;
    font-weight: 700;
  }
  .login-entry__buch-kaufdatum--beschriftung--desktop {
    display: block;
  }
  .login-entry__buch-kaufdatum--beschriftung--mobile {
    display: none;
  }
  .login-entry__buch-e-book {
    width: 14.27vw;
    font-weight: 300;
  }
  .login-entry__buch-e-book--buch {
    margin: 0vw 0.42vw 0vw 0.42vw;
  }
  .login-entry__buch-e-book--beschriftung {
    margin: 0vw 0.42vw 0vw 0.42vw;
    font-weight: 700;
  }
  .login-entry__buch-e-book--beschriftung--desktop {
    display: block;
  }
  .login-entry__buch-e-book--beschriftung--mobile {
    display: none;
  }
  .login-entry__buch-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 8.59vw;
  }
  .login-entry__buch-uebungen--buch {
    margin: 0vw 0.42vw 0vw 0.42vw;
  }
  .login-entry__buch-uebungen--beschriftung {
    margin: 0vw 0.42vw 0vw 0.42vw;
    font-weight: 700;
  }
  .login-entry__buch-uebungen--beschriftung--desktop {
    display: block;
  }
  .login-entry__buch-uebungen--beschriftung--mobile {
    display: none;
  }
}
.login {
  font-family: "Neue Haas Unica W1G", sans-serif;
  background-color: #282828;
  color: white;
  min-height: 100vh;
}
.login a {
  text-decoration: underline;
  color: #fff;
}
.login__anmelden {
  appearance: none;
  background-color: white;
  border: none;
  color: black;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 699px) {
  .login {
    padding-top: 12.31vw;
  }
  .login__container {
    padding: 5.64vw 7.69vw 46.92vw 7.69vw;
  }
  .login__feld {
    margin-top: 2.56vw;
    position: relative;
  }
  .login__input {
    padding: 5.13vw 2.56vw 1.28vw 2.56vw;
    font-size: 4.62vw;
    line-height: 3.59vw;
    font-weight: 700;
    width: 100%;
    border: 0;
    background-color: white;
    border-bottom: 1px solid black;
    border-radius: 0;
    cursor: text;
    -webkit-appearance: none;
  }
  .login__input:focus {
    outline: 0;
    border-bottom: 1px solid black;
  }
  .login__input:placeholder-shown + label {
    cursor: text;
  }
  .login__input:not(:placeholder-shown) + label, .login__input:focus + label {
    font-size: 3.08vw;
    line-height: 3.59vw;
    font-weight: 300;
    cursor: pointer;
    bottom: 7.69vw;
  }
  .login__input:not(:placeholder-shown):invalid {
    box-shadow: none;
    border-bottom-color: red;
  }
  .login__input:not(:placeholder-shown):invalid + label {
    box-shadow: none;
    color: red;
  }
  .login__input:-moz-submit-invalid {
    box-shadow: none;
  }
  .login__input:-moz-ui-invalid {
    box-shadow: none;
  }
  .login__label {
    font-size: 4.62vw;
    line-height: 3.85vw;
    font-weight: 300;
    width: 100%;
    bottom: 6.41vw;
    left: 2.56vw;
    position: absolute;
    transition: all 0.2s;
    touch-action: manipulation;
    z-index: 1;
    color: #000;
  }
  .login__anmelden {
    margin-top: 11.54vw;
    padding: 0vw 2.56vw 0vw 2.56vw;
    font-size: 4.62vw;
    line-height: 4.62vw;
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 7.69vw;
  }
  .login__meldung {
    margin-top: 2.56vw;
    font-size: 4.62vw;
    line-height: 5.9vw;
    font-weight: 300;
  }
}
@media (min-width: 700px) {
  .login {
    padding-top: 2.5vw;
  }
  .login__container {
    padding: 2.34vw 2.6vw 5.21vw 2.6vw;
  }
  .login__feld {
    margin-top: 0.52vw;
    position: relative;
  }
  .login__input {
    padding: 1.04vw 0.52vw 0.26vw 0.52vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
    font-weight: 700;
    width: calc((100% - 0vw - 2.6vw) / 2 * 1 + 0vw + 0vw);
    border: 0;
    background-color: white;
    border-bottom: 1px solid black;
    border-radius: 0;
    cursor: text;
    -webkit-appearance: none;
  }
  .login__input:focus {
    outline: 0;
    border-bottom: 1px solid black;
  }
  .login__input:placeholder-shown + label {
    cursor: text;
  }
  .login__input:not(:placeholder-shown) + label, .login__input:focus + label {
    font-size: 0.73vw;
    line-height: 1.46vw;
    font-weight: 300;
    cursor: pointer;
    bottom: 1.56vw;
  }
  .login__input:not(:placeholder-shown):invalid {
    box-shadow: none;
    border-bottom-color: red;
  }
  .login__input:not(:placeholder-shown):invalid + label {
    box-shadow: none;
    color: red;
  }
  .login__input:-moz-submit-invalid {
    box-shadow: none;
  }
  .login__input:-moz-ui-invalid {
    box-shadow: none;
  }
  .login__label {
    font-size: 1.15vw;
    line-height: 1.46vw;
    width: 100%;
    bottom: 1.3vw;
    left: 0.52vw;
    position: absolute;
    transition: all 0.2s;
    touch-action: manipulation;
    z-index: 1;
    color: #000;
  }
  .login__anmelden {
    margin-top: 2.34vw;
    padding: 0vw 0.52vw 0vw 0.52vw;
    font-size: 1.15vw;
    line-height: 1.15vw;
    font-weight: 700;
    align-items: center;
    display: flex;
    height: 2.08vw;
  }
  .login__meldung {
    margin-top: 0.52vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
    font-weight: 300;
  }
}
.menue__menue-link {
  font-weight: 300;
  font-family: "Neue Haas Unica W1G", sans-serif;
}
.menue__menue-link--buch {
  font-weight: 800;
  text-decoration: none;
}
.menue__menue-link--regular {
  font-weight: 300;
  text-decoration: none;
}
.menue__menue-impressum {
  font-family: "Neue Haas Unica W1G", sans-serif;
}
.menue__menue-impressum-link {
  font-weight: 300;
  color: black;
  text-decoration: none;
}
.menue__menue-impressum-link:hover, .menue__menue-impressum-link[data-link-aktiv=true] {
  font-weight: 700;
}
.menue__menue-link-span[data-link-aktiv=true][data-menue-link="1"], .menue__menue-link-span[data-menue-link="1"]:hover {
  color: white;
}
.menue__menue-link-span[data-link-aktiv=true][data-menue-link="2"], .menue__menue-link-span[data-menue-link="2"]:hover {
  background-color: black;
}
.menue__menue-link-span--regular[data-link-aktiv=true][data-menue-link="2"], .menue__menue-link-span--regular[data-menue-link="2"]:hover {
  color: white;
}
.menue__menue-link-span--word[data-link-aktiv=true], .menue__menue-link-span--word:hover {
  background-color: #4676C1;
}
.menue__menue-link-span--excel[data-link-aktiv=true], .menue__menue-link-span--excel:hover {
  background-color: #0F9844;
}
.menue__menue-link-span--powerpoint[data-link-aktiv=true], .menue__menue-link-span--powerpoint:hover {
  background-color: #EF5A18;
}
.menue__menue-link-span--junior[data-link-aktiv=true], .menue__menue-link-span--junior:hover {
  color: white;
}
.menue__menue-link-span--shorty[data-link-aktiv=true], .menue__menue-link-span--shorty:hover {
  color: #F5FF00;
}

@media (max-width: 699px) {
  .menue {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .body[data-menue-aktiv=true] .menue {
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
  }
  .menue__menue {
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.5s;
    width: 100%;
    z-index: 90;
  }
  .body[data-menue-aktiv=true] .menue__menue {
    height: calc(var(--vh, 1vh) * 100);
  }
  .menue__menue-logo--text {
    display: none;
    position: absolute;
    height: 17.18vw;
    left: 22.05vw;
    top: 12.56vw;
    width: 48.97vw;
  }
  .body--startseite[data-menue-aktiv=false] .menue__menue-logo--text {
    display: block;
  }
  .menue__menue-logo--normal {
    display: block;
    position: absolute;
    height: 8.08vw;
    left: 7.69vw;
    top: 12.56vw;
    width: 35.9vw;
  }
  .body--startseite[data-menue-aktiv=false] .menue__menue-logo--normal {
    display: none;
  }
  .menue__menue-titel--1 {
    display: none;
    position: absolute;
    height: 44.87vw;
    left: 7.69vw;
    top: 12.56vw;
    width: 13.85vw;
  }
  .body--startseite[data-menue-aktiv=false] .menue__menue-titel--1 {
    display: block;
  }
  .menue__menue-titel--2 {
    margin: 35.9vw 0vw 0vw 7.69vw;
    height: 103.33vw;
    max-height: 41.6vh;
    width: 21.79vw;
  }
  .menue__menue-container {
    display: none;
  }
  .body[data-menue-aktiv=true] .menue__menue-container {
    display: flex;
    flex-wrap: wrap;
  }
  .menue__menue-container--2 {
    margin-top: 6.41vw;
    margin-left: calc((100% - 0vw - 7.69vw) / 2 * 0 + 7.69vw + 0vw);
  }
  .menue__menue-container-box {
    margin-top: 34.1vw;
  }
  .menue__menue-links {
    display: flex;
    flex-direction: column;
  }
  .menue__menue-links--1, .menue__menue-links--2 {
    margin: 0vw -2.56vw 5.13vw -2.56vw;
  }
  .menue__menue-link {
    margin-top: 0.51vw;
    font-size: 9.23vw;
    color: #000;
  }
  .menue__menue-link:first-child {
    margin-top: -1.28vw;
  }
  .menue__menue-link--regular {
    font-size: 5.9vw;
  }
  .menue__menue-link-span {
    display: inline-flex;
    align-items: center;
  }
  .menue__menue-link-span--buch {
    height: 14.1vw;
    padding: 0vw 2.56vw 0vw 2.56vw;
  }
  .menue__menue-link-span--regular {
    height: 8.97vw;
    padding: 0vw 2.05vw 0vw 2.05vw;
  }
  .menue__menue-impressum {
    margin: 3.85vw -2.56vw 0vw 5.13vw;
    display: none;
  }
  .body[data-menue-aktiv=true] .menue__menue-impressum {
    display: flex;
    flex-wrap: wrap;
  }
  .menue__menue-impressum-link {
    margin: 0vw 2.56vw 0vw 2.56vw;
    font-size: 3.85vw;
    line-height: 7.18vw;
  }
  .menue__hamburger {
    display: block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    line-height: 0;
    padding: 5.13vw 5.13vw 5.13vw 5.13vw;
    margin-right: 2.56vw;
    position: absolute;
    right: 0;
    top: 9.1vw;
    z-index: 120;
  }
  .menue__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .menue__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner,
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::before,
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::after {
    background-color: black;
    transition: 0s 0.12s linear;
  }
  .menue__hamburger .hamburger-box {
    width: 11.03vw;
    height: 8.21vw;
    display: inline-block;
    position: relative;
  }
  .menue__hamburger .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -0.77vw;
  }
  .menue__hamburger .hamburger-inner, .menue__hamburger .hamburger-inner::before, .menue__hamburger .hamburger-inner::after {
    width: 11.03vw;
    height: 1.54vw;
    background-color: black;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .menue__hamburger .hamburger-inner::before, .menue__hamburger .hamburger-inner::after {
    content: "";
    display: block;
  }
  .menue__hamburger .hamburger-inner::before {
    top: -3.335vw;
  }
  .menue__hamburger .hamburger-inner::after {
    bottom: -3.335vw;
  }
  .menue__hamburger .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .menue__hamburger .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .menue__hamburger .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
@media (min-width: 700px) {
  .menue {
    height: 100%;
    height: 100%;
    position: absolute;
    left: 7.29vw;
    z-index: 130;
    top: 0;
  }
  .menue__menue {
    transition: 0.5s;
    position: sticky;
    top: 2.14vw;
    margin-top: 2.14vw;
  }
  .menue__menue-logo--normal {
    display: none;
  }
  .menue__menue-logo--text {
    margin-bottom: 2.44vw;
    width: 10.55vw;
    height: 3.7vw;
  }
  .menue__menue-container {
    display: flex;
    flex-wrap: wrap;
  }
  .menue__menue-container--2 {
    margin-top: 1.17vw;
    flex-direction: column;
  }
  .menue__menue-titel--1 {
    display: none;
  }
  .menue__menue-titel--2 {
    margin: 0.56vw 1.55vw 0.05vw 0vw;
    width: 4.1vw;
    height: 26.46vw;
    max-height: 48.5829vh;
    min-height: 13.23vw;
  }
  .menue__menue-links {
    margin-bottom: 1.3vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .body--startseite .menue__menue-links--1 {
    margin: -0.3vw 0vw 1.56vw 0vw;
  }
  .body--startseite .menue__menue-links--2 {
    margin-top: -0.3vw;
  }
  .menue__menue-link {
    color: black;
    display: inline-flex;
    position: relative;
    margin: 0.19vw -0.56vw -0.09vw -0.56vw;
    font-size: 1.69vw;
  }
  .body--startseite .menue__menue-link--buch {
    margin: 0vw -0.56vw -0.09vw -0.56vw;
    font-size: 3.05vw;
  }
  .menue__menue-link-span {
    display: flex;
    align-items: center;
    padding: 0vw 0.56vw 0vw 0.56vw;
    height: 2.08vw;
  }
  .body--startseite .menue__menue-link-span--buch {
    padding: 0vw 0.56vw 0vw 0.56vw;
    height: 3.91vw;
  }
  .menue__menue-impressum {
    margin: 1.56vw -0.47vw 0.1vw -0.47vw;
  }
  .menue__menue-impressum-link {
    margin: 0vw 0.47vw 0.1vw 0.47vw;
    font-size: 0.7vw;
    line-height: 1.31vw;
  }
  .menue__hamburger {
    display: none;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.shop {
  background-color: #282828;
  color: white;
  font-family: "Neue Haas Unica W1G", sans-serif;
  min-height: 100vh;
  font-weight: 300;
}
.shop__buch-input {
  background-color: #fff;
  border-color: #000;
  border-style: solid;
  border-width: 1px 0 1px 0;
  box-sizing: border-box;
  outline: none;
}
.shop__buch-input-buttons {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  outline: none;
  color: #000;
}
.shop__buch-titel, .shop__buch-shorty-titel {
  font-weight: 700;
  color: #000;
  text-decoration: none;
}
.shop__buch-titel a, .shop__buch-shorty-titel a {
  color: #000;
  text-decoration: none;
}
.shop__buch-warenkorb {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
}
.shop__buch-svg-text {
  font-family: "Neue Haas Unica W1G", sans-serif;
}
.shop__buch-bild {
  text-decoration: none;
  color: black;
}
.shop__shorty-cover {
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.shop__shorty-cover-container {
  background-color: #F5FF00;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(340deg);
}
.shop__shorty-cover-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.shop__shorty-cover-text::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  width: 100%;
}

@media (max-width: 699px) {
  .shop {
    margin: 0vw 0vw -52.56vw 0vw;
  }
  .shop__container {
    padding: 5.64vw 7.69vw 46.92vw 7.69vw;
  }
  .shop__titel {
    padding-bottom: 2.56vw;
    font-size: 9.23vw;
    line-height: 10.26vw;
    font-weight: 300;
    border-bottom: 0.51vw solid white;
  }
  .shop__buecher {
    margin: 3.85vw 0vw 3.85vw 0vw;
    display: flex;
    flex-wrap: wrap;
  }
  .shop__buch {
    margin: 3.85vw 0vw 3.85vw 0vw;
    padding: 3.85vw 5.13vw 3.85vw 5.13vw;
    font-size: 4.62vw;
    line-height: 5.9vw;
    background-color: #fff;
    color: black;
    width: 100%;
    position: relative;
  }
  .shop__buch--mobile {
    display: flex;
  }
  .shop__buch--desktop {
    display: none;
  }
  .shop__buch-container-mobile {
    position: relative;
  }
  .shop__buch-container-mobile--1 {
    display: flex;
  }
  .shop__buch-container-mobile--2 {
    margin-left: 5.13vw;
  }
  .shop__buch-container-desktop {
    display: none;
  }
  .shop__buch-bild {
    width: 30.51vw;
  }
  .shop__buch-svg {
    position: absolute;
    width: 13.33vw;
    height: 13.33vw;
    transform: rotate(-20deg);
    top: 2.56vw;
    right: 2.56vw;
  }
  .shop__buch-input-container {
    margin-top: 2.56vw;
    display: flex;
    position: relative;
    height: 7.69vw;
  }
  .shop__buch-input {
    padding: 0vw 1.79vw 0vw 1.79vw;
    font-size: 4.36vw;
    line-height: 4.36vw;
    width: 59.0277777778%;
  }
  .shop__buch-input-buttons {
    font-size: 4.36vw;
    line-height: 4.36vw;
    width: 20.1388888889%;
    padding: 0;
  }
  .shop__buch-warenkorb {
    margin-top: 3.85vw;
    font-size: 3.85vw;
    line-height: 3.85vw;
    width: 99.3055555556%;
    height: 7.69vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .shop__shorty-cover-text {
    font-size: 5.38vw;
    line-height: 7.69vw;
    font-weight: bold;
  }
  .shop__shorty-cover-text::after {
    height: 0.77vw;
  }
}
@media (min-width: 700px) {
  .shop {
    margin: -1.98vw -2.6vw -7.55vw -2.6vw;
  }
  .shop__container {
    padding: 2.34vw 2.6vw 5.21vw 2.6vw;
  }
  .shop__titel {
    padding-bottom: 1.35vw;
    font-size: 3.13vw;
    line-height: 2.08vw;
    font-weight: 300;
    border-bottom: 0.16vw solid white;
  }
  .shop__buecher {
    margin-top: 1.82vw;
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.28vw;
    margin-right: -1.28vw;
  }
  .shop__buch {
    margin: 1.04vw 0vw 1.04vw 0vw;
    padding: 1.09vw 1.15vw 1.09vw 1.15vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
    background-color: #fff;
    color: black;
    margin-left: 1.28vw;
    margin-right: 1.28vw;
    width: calc((100% - 0vw - 10.4vw) / 4 * 1 + 0vw + 0vw);
  }
  .shop__buch--mobile {
    display: none;
  }
  .shop__buch--desktop {
    display: block;
    align-content: space-between;
    position: relative;
  }
  .shop__buch-container-desktop .shop__buch-sticker {
    margin-bottom: -1.3vw;
    display: flex;
    flex-wrap: wrap;
  }
  .shop__buch-container-desktop--1 {
    display: flex;
    justify-content: space-between;
  }
  .shop__buch-container-desktop--2 {
    width: 5.47vw;
  }
  .shop__buch-container-desktop--3 {
    position: absolute;
    bottom: 1.04vw;
  }
  .shop__buch-container-mobile {
    display: none;
  }
  .shop__buch-svg {
    width: 3.91vw;
    height: 3.91vw;
    transform: rotate(-20deg);
  }
  .shop__buch-bild {
    margin: 0vw 1.04vw 0.52vw 0vw;
    width: 6.25vw;
  }
  .shop__buch-preis {
    margin-bottom: 7.19vw;
  }
  .shop__buch-input-container {
    display: flex;
    position: relative;
    height: 2.08vw;
  }
  .shop__buch-input {
    padding: 0.16vw 0.57vw 0.26vw 0.57vw;
    font-size: 1.04vw;
    line-height: 1.04vw;
    width: 7.55vw;
  }
  .shop__buch-input-buttons {
    font-size: 1.04vw;
    line-height: 1.04vw;
    padding: 0;
    width: 1.93vw;
  }
  .shop__buch-warenkorb {
    margin-top: 0.73vw;
    font-size: 0.94vw;
    line-height: 0.94vw;
    font-weight: 700;
    width: 11.51vw;
    height: 2.08vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .shop__shorty-cover-text {
    font-size: 1.15vw;
    line-height: 1.67vw;
    font-weight: bold;
  }
  .shop__shorty-cover-text::after {
    height: 0.16vw;
  }
}
.startseite {
  font-family: "Neue Haas Unica W1G", sans-serif;
}
.startseite__kreis {
  display: block;
  text-decoration: none;
  color: #000;
}
.body[data-menue-aktiv=true] .startseite__kreis {
  display: none;
}
.startseite__startseite[data-status=inaktiv] {
  display: none;
}
.startseite__startseite[data-status=aktiv] {
  display: block;
}
.startseite__zeit[data-text-color=schwarz] + .startseite__bild::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 25%);
  pointer-events: none;
}
.startseite__zeit {
  z-index: 10;
}
.startseite__zeit[data-text-color=weiss] {
  color: white;
}
.startseite__zeit[data-text-color=schwarz] {
  color: #000;
}

@media (max-width: 699px) {
  .startseite {
    margin: 0vw -7.69vw 0vw -7.69vw;
    height: calc(var(--vh, 1vh) * 100 - 30.77vw);
    position: relative;
  }
  .startseite__kreis {
    padding: 0vw 7.69vw 0vw 7.69vw;
    font-size: 4.1vw;
    line-height: 4.62vw;
    font-weight: 300;
    background: #F5FF00;
    border-radius: 50%;
    /* Korrekt */
    top: 5.13vw;
    display: inline-block;
    right: 5.13vw;
    position: absolute;
    text-align: center;
    transform: rotate(-20deg);
    width: 51.28vw;
    z-index: 1000;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 51.28vw;
    overflow: hidden;
  }
  .startseite__kreis:before, .startseite__kreis:after {
    content: "";
    display: block;
    padding: 25%;
  }
  .startseite__kreis-text {
    display: inline-block;
  }
  .startseite__kreis-text--titel {
    margin: 1.28vw -7.69vw 6.15vw -7.69vw;
    font-size: 6.41vw;
    line-height: 7.69vw;
    font-weight: 700;
    position: relative;
  }
  .startseite__kreis-text--titel::before {
    content: "";
    position: absolute;
    bottom: -3.85vw;
    left: 0;
    width: 100%;
    height: 1.28vw;
    background-color: black;
  }
  .startseite__kreis-text--aktion {
    font-size: 4.1vw;
    line-height: 4.62vw;
    font-weight: 700;
    color: #000;
    text-decoration: none;
  }
  .startseite__bild {
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(var(--vh, 1vh) * 100 - 64.1vw);
    width: 100%;
  }
  .startseite__zeit[data-text-color=schwarz] + .startseite__bild::after {
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 60%);
  }
  .startseite__zeit {
    bottom: 12.05vw;
    position: absolute;
    left: 7.69vw;
  }
  .startseite__zeit--zeit {
    font-size: 16.67vw;
    line-height: 16.67vw;
    font-weight: 300;
  }
  .startseite__zeit--text {
    font-size: 9.23vw;
    line-height: 10.26vw;
    font-weight: 300;
  }
}
@media (min-width: 700px) {
  .startseite {
    padding-left: 8.85vw;
    position: relative;
  }
  .startseite__kreis {
    padding: 0vw 1.82vw 0vw 1.82vw;
    font-size: 1.15vw;
    line-height: 1.2vw;
    font-weight: 300;
    background: #F5FF00;
    border-radius: 50%;
    bottom: 14.58vw;
    display: inline-block;
    left: 0;
    position: absolute;
    text-align: center;
    transform: rotate(-20deg);
    width: 13.02vw;
    z-index: 1000;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 13.02vw;
    overflow: hidden;
  }
  .startseite__kreis:before, .startseite__kreis:after {
    content: "";
    display: block;
    padding: 25%;
  }
  .startseite__kreis-text {
    display: inline-block;
  }
  .startseite__kreis-text--titel {
    margin: 0.26vw -1.82vw 1.56vw -1.82vw;
    font-size: 1.67vw;
    line-height: 1.98vw;
    font-weight: 700;
    position: relative;
  }
  .startseite__kreis-text--titel::before {
    content: "";
    position: absolute;
    bottom: -0.78vw;
    left: 0;
    width: 100%;
    height: 0.26vw;
    background-color: black;
  }
  .startseite__kreis-text--aktion {
    font-size: 1.09vw;
    line-height: 1.2vw;
    font-weight: 700;
    color: #000;
    text-decoration: none;
  }
  .startseite__bild {
    height: 100vh;
    width: 75.73vw;
  }
  .startseite__bild::after {
    width: 75.73vw;
  }
  .startseite__zeit {
    bottom: 2.45vw;
    position: absolute;
    left: 13.13vw;
  }
  .startseite__zeit--zeit {
    font-size: 6.25vw;
    line-height: 6.25vw;
    font-weight: 300;
  }
  .startseite__zeit--text {
    font-size: 3.39vw;
    line-height: 3.39vw;
    font-weight: 300;
  }
}
.verlag {
  font-family: "Neue Haas Unica W1G", sans-serif;
  background-color: #282828;
  color: white;
  min-height: 100vh;
}
.verlag__titel {
  width: 100%;
}
.verlag a {
  text-decoration: underline;
  color: #fff;
}
.verlag__verlag-text p {
  font-weight: 300;
}
.verlag__verlag-text ul {
  font-weight: 300;
}
.verlag__verlag-text strong {
  font-weight: 700;
}

@media (max-width: 699px) {
  .verlag {
    margin: 0vw 0vw -52.56vw 0vw;
  }
  .verlag__container {
    padding: 5.64vw 7.69vw 46.92vw 7.69vw;
  }
  .verlag__titel {
    padding-bottom: 2.56vw;
    font-size: 9.23vw;
    line-height: 10.26vw;
    font-weight: 300;
    border-bottom: 0.51vw solid white;
  }
  .verlag__introtext {
    padding: 5.64vw 0vw 5.64vw 0vw;
    font-size: 5.64vw;
    line-height: 7.18vw;
    font-weight: 700;
  }
  .verlag__verlag-ueberschrift {
    margin: 8.97vw 0vw 8.97vw 0vw;
    font-weight: 700;
  }
  .verlag__verlag-texte {
    padding: 5.64vw 0vw 5.64vw 0vw;
    font-size: 4.62vw;
    line-height: 5.9vw;
    font-weight: 300;
  }
  .verlag__verlag-texte ul {
    margin: 8.97vw 0vw 8.97vw 0vw;
  }
  .verlag__verlag-texte ul li {
    padding-left: 3.33vw;
  }
  .verlag__verlag-texte ol {
    margin: 8.97vw 0vw 8.97vw 0vw;
    padding-left: 5.13vw;
  }
  .verlag__verlag-texte p {
    margin: 8.97vw 0vw 8.97vw 0vw;
  }
  .verlag__verlag-texte *:first-child {
    margin-top: 0vw;
  }
  .verlag__verlag-texte *:last-child {
    margin-bottom: 0vw;
  }
}
@media (min-width: 700px) {
  .verlag {
    margin: -1.98vw -2.6vw -7.55vw -2.6vw;
  }
  .verlag__container {
    padding: 2.34vw 2.6vw 5.21vw 2.6vw;
  }
  .verlag__titel {
    padding-bottom: 1.35vw;
    font-size: 3.13vw;
    line-height: 2.08vw;
    font-weight: 300;
    border-bottom: 0.16vw solid white;
  }
  .verlag__introtext {
    padding: 2.5vw 0vw 2.5vw 0vw;
    font-size: 1.88vw;
    line-height: 2.19vw;
    font-weight: 700;
  }
  .verlag__verlag {
    margin-bottom: -2.5vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .verlag__verlag-ueberschrift {
    margin: 1.82vw 0vw 1.82vw 0vw;
    font-weight: 700;
  }
  .verlag__verlag-texte {
    padding-top: 2.5vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
    width: calc((100% - 0vw - 7.8vw) / 4 * 2 + 2.6vw + 0vw);
  }
  .verlag__verlag-texte p {
    margin: 1.82vw 0vw 1.82vw 0vw;
  }
  .verlag__verlag-texte ul {
    margin: 1.82vw 0vw 1.82vw 0vw;
  }
  .verlag__verlag-texte ul li {
    padding-left: 0.68vw;
  }
  .verlag__verlag-texte ol {
    padding-left: 1.2vw;
  }
  .verlag__verlag-texte *:first-child {
    margin-top: 0vw;
  }
  .verlag__verlag-texte *:last-child {
    margin-bottom: 0vw;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.warenkorb-seite {
  background-color: #282828;
  color: white;
  font-family: "Neue Haas Unica W1G", sans-serif;
  min-height: 100vh;
}
.warenkorb-seite a {
  color: white;
  text-decoration: underline;
}
.warenkorb-seite__buch-titel--beschriftung, .warenkorb-seite__buch-kategorie--beschriftung, .warenkorb-seite__buch-anzahl--beschriftung, .warenkorb-seite__buch-einzelpreis--beschriftung, .warenkorb-seite__buch-preis--beschriftung {
  font-weight: 700;
}
.warenkorb-seite__buch-loeschen {
  cursor: pointer;
}
.warenkorb-seite__buch-input-container {
  display: flex;
  position: relative;
}
.warenkorb-seite__buch-input {
  background-color: #fff;
  border-color: #000;
  border-style: solid;
  border-width: 1px 0 1px 0;
  box-sizing: border-box;
  outline: none;
}
.warenkorb-seite__buch-input-buttons {
  background-color: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
}
.warenkorb-seite__buch-container-preis-button {
  display: flex;
}
.warenkorb-seite__buch-loeschen {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@media (max-width: 699px) {
  .warenkorb-seite {
    margin: 0vw 0vw -52.56vw 0vw;
  }
  .warenkorb-seite__container {
    padding: 5.64vw 7.69vw 46.92vw 7.69vw;
  }
  .warenkorb-seite__titel {
    padding-bottom: 2.56vw;
    font-size: 9.23vw;
    line-height: 10.26vw;
    font-weight: 300;
    border-bottom: 0.51vw solid #fff;
  }
  .warenkorb-seite__introtext {
    padding-top: 5.13vw;
    font-size: 5.64vw;
    line-height: 7.18vw;
    font-weight: 300;
  }
  .warenkorb-seite__warenkorb-container {
    margin-top: 30.77vw;
  }
  .warenkorb-seite__warenkorb-titel {
    font-size: 5.64vw;
    line-height: 10.26vw;
    font-weight: 700;
    padding-bottom: 2.56vw;
  }
  .warenkorb-seite__warenkorb-titel--warenkorb {
    padding-bottom: 5.64vw;
    border-bottom: 1px solid #fff;
  }
  .warenkorb-seite__buch {
    padding: 8.72vw 0vw 8.72vw 0vw;
    border-bottom: 1px solid #fff;
  }
  .warenkorb-seite__buch-beschriftung {
    display: none;
  }
  .warenkorb-seite__buch-titel--beschriftung, .warenkorb-seite__buch-kategorie--beschriftung, .warenkorb-seite__buch-anzahl--beschriftung, .warenkorb-seite__buch-einzelpreis--beschriftung, .warenkorb-seite__buch-loeschen--beschriftung {
    font-size: 4.62vw;
    line-height: 6.15vw;
  }
  .warenkorb-seite__buch-titel--beschriftung--desktop, .warenkorb-seite__buch-kategorie--beschriftung--desktop, .warenkorb-seite__buch-anzahl--beschriftung--desktop, .warenkorb-seite__buch-einzelpreis--beschriftung--desktop, .warenkorb-seite__buch-loeschen--beschriftung--desktop {
    display: none;
  }
  .warenkorb-seite__buch-titel--beschriftung--mobile, .warenkorb-seite__buch-kategorie--beschriftung--mobile, .warenkorb-seite__buch-anzahl--beschriftung--mobile, .warenkorb-seite__buch-einzelpreis--beschriftung--mobile, .warenkorb-seite__buch-loeschen--beschriftung--mobile {
    display: block;
  }
  .warenkorb-seite__buch-titel--buch, .warenkorb-seite__buch-kategorie--buch, .warenkorb-seite__buch-anzahl--buch, .warenkorb-seite__buch-einzelpreis--buch, .warenkorb-seite__buch-loeschen--buch {
    margin-bottom: 5.13vw;
    font-size: 4.62vw;
    line-height: 6.15vw;
  }
  .warenkorb-seite__buch-preis--beschriftung {
    font-size: 4.62vw;
    line-height: 6.15vw;
  }
  .warenkorb-seite__buch-preis--beschriftung--desktop {
    display: none;
  }
  .warenkorb-seite__buch-preis--beschriftung--mobile {
    display: block;
  }
  .warenkorb-seite__buch-preis--buch {
    font-size: 4.62vw;
    line-height: 6.15vw;
  }
  .warenkorb-seite__buch-anzahl--buch {
    margin-top: 1.79vw;
  }
  .warenkorb-seite__buch-input-container {
    height: 10vw;
  }
  .warenkorb-seite__buch-input {
    padding: 0vw 2.82vw 0vw 2.82vw;
    font-size: 5.64vw;
    line-height: 5.64vw;
    text-align: center;
    width: 20.77vw;
  }
  .warenkorb-seite__buch-input-buttons {
    font-size: 5.64vw;
    line-height: 5.64vw;
    padding: 0;
    width: 8.97vw;
  }
  .warenkorb-seite__buch-container-preis-button {
    margin-bottom: 5.13vw;
    justify-content: space-between;
  }
  .warenkorb-seite__buch-loeschen--desktop {
    display: none;
  }
  .warenkorb-seite__buch-loeschen--mobile {
    display: flex;
    align-items: flex-end;
  }
  .warenkorb-seite__buch-loeschen-button {
    padding: 0vw 2.56vw 0vw 2.56vw;
    font-size: 4.62vw;
    line-height: 7.18vw;
    font-weight: 700;
    background-color: #fff;
    color: #000;
    border: none;
    cursor: pointer;
    outline: inherit;
    height: 7.69vw;
    align-items: center;
    display: flex;
  }
  .warenkorb-seite__total-container {
    margin-bottom: 27.95vw;
    display: flex;
  }
  .warenkorb-seite__total {
    margin-top: 6.92vw;
    font-size: 5.64vw;
    line-height: 6.15vw;
    font-weight: 700;
  }
  .warenkorb-seite__total--beschriftung {
    width: 15.9vw;
  }
  .warenkorb-seite__total--buch {
    width: auto;
    font-weight: 300;
  }
}
@media (min-width: 700px) {
  .warenkorb-seite {
    margin: -1.98vw -2.6vw -7.55vw -2.6vw;
  }
  .warenkorb-seite__container {
    padding: 2.34vw 2.6vw 5.21vw 2.6vw;
  }
  .warenkorb-seite__titel {
    padding-bottom: 1.35vw;
    font-size: 3.13vw;
    line-height: 2.08vw;
    font-weight: 300;
    border-bottom: 0.16vw solid white;
    width: 100%;
  }
  .warenkorb-seite__introtext {
    padding-top: 2.81vw;
    font-size: 1.15vw;
    line-height: 1.46vw;
    font-weight: 300;
  }
  .warenkorb-seite__warenkorb-container {
    padding: 4.38vw 0vw 5.68vw 0vw;
  }
  .warenkorb-seite__warenkorb-titel {
    margin-bottom: 2.66vw;
    font-size: 1.88vw;
    line-height: 2.08vw;
    font-weight: 700;
  }
  .warenkorb-seite__buch-beschriftung-border {
    padding: 0vw 0vw 0.63vw 0vw;
    font-size: 0.94vw;
    line-height: 0.94vw;
    border-bottom: 1px solid #fff;
  }
  .warenkorb-seite__buch-beschriftung {
    margin: 0vw -0.42vw 0vw -0.42vw;
    display: flex;
  }
  .warenkorb-seite__buch {
    padding-bottom: 0.47vw;
    font-size: 1.15vw;
    line-height: 3.13vw;
    border-bottom: 1px solid #fff;
  }
  .warenkorb-seite__buch-container {
    margin: 0vw -0.42vw 0vw -0.42vw;
    display: flex;
  }
  .warenkorb-seite__buch-titel {
    margin: 0vw 0.42vw 0vw 0.42vw;
    width: 22.45vw;
    font-weight: 300;
  }
  .warenkorb-seite__buch-titel--beschriftung {
    font-weight: 700;
  }
  .warenkorb-seite__buch-titel--beschriftung--desktop {
    display: block;
  }
  .warenkorb-seite__buch-titel--beschriftung--mobile {
    display: none;
  }
  .warenkorb-seite__buch-kategorie {
    margin: 0vw 0.42vw 0vw 0.42vw;
    width: 7.24vw;
    font-weight: 300;
  }
  .warenkorb-seite__buch-kategorie--beschriftung {
    margin: 0vw 0.42vw 0vw 0.42vw;
    font-weight: 700;
  }
  .warenkorb-seite__buch-kategorie--beschriftung--desktop {
    display: block;
  }
  .warenkorb-seite__buch-kategorie--beschriftung--mobile {
    display: none;
  }
  .warenkorb-seite__buch-anzahl {
    width: 10.42vw;
    font-weight: 300;
  }
  .warenkorb-seite__buch-anzahl--buch {
    margin: 0.68vw 0.42vw 0vw 0.42vw;
  }
  .warenkorb-seite__buch-anzahl--beschriftung {
    margin: 0vw 0.42vw 0vw 0.42vw;
    font-weight: 700;
  }
  .warenkorb-seite__buch-anzahl--beschriftung--desktop {
    display: block;
  }
  .warenkorb-seite__buch-anzahl--beschriftung--mobile {
    display: none;
  }
  .warenkorb-seite__buch-input-container {
    height: 2.03vw;
  }
  .warenkorb-seite__buch-input {
    padding: 0vw 0.57vw 0vw 0.57vw;
    font-size: 1.15vw;
    line-height: 1.15vw;
    text-align: center;
    width: 4.22vw;
  }
  .warenkorb-seite__buch-input-buttons {
    font-size: 1.15vw;
    line-height: 1.15vw;
    padding: 0;
    width: 1.82vw;
  }
  .warenkorb-seite__buch-einzelpreis {
    margin: 0vw 0.42vw 0vw 0.42vw;
    width: 6.72vw;
    font-weight: 300;
  }
  .warenkorb-seite__buch-einzelpreis--beschriftung {
    margin: 0vw 0.42vw 0vw 0.42vw;
    font-weight: 700;
  }
  .warenkorb-seite__buch-einzelpreis--beschriftung--desktop {
    display: block;
  }
  .warenkorb-seite__buch-einzelpreis--beschriftung--mobile {
    display: none;
  }
  .warenkorb-seite__buch-preis {
    margin: 0vw 0.42vw 0vw 0.42vw;
    width: 7.92vw;
    font-weight: 300;
  }
  .warenkorb-seite__buch-preis--beschriftung {
    margin: 0vw 0.42vw 0vw 0.42vw;
    font-weight: 700;
  }
  .warenkorb-seite__buch-preis--beschriftung--desktop {
    display: block;
  }
  .warenkorb-seite__buch-preis--beschriftung--mobile {
    display: none;
  }
  .warenkorb-seite__buch-loeschen {
    margin: 0vw 0.42vw 0vw 0.42vw;
    width: 4.74vw;
  }
  .warenkorb-seite__buch-loeschen--desktop {
    display: flex;
  }
  .warenkorb-seite__buch-loeschen--mobile {
    display: none;
  }
  .warenkorb-seite__buch-loeschen-text {
    margin-left: 0.31vw;
    font-size: 0.94vw;
    line-height: 3.13vw;
    font-weight: 700;
  }
  .warenkorb-seite__buch-loeschen-svg {
    margin-top: 1.25vw;
    width: 0.73vw;
    height: 0.73vw;
  }
  .warenkorb-seite__total-container {
    display: flex;
  }
  .warenkorb-seite__total {
    font-size: 1.15vw;
    line-height: 3.13vw;
    font-weight: 700;
  }
  .warenkorb-seite__total--beschriftung {
    width: 50.1vw;
  }
  .warenkorb-seite__total--buch {
    width: 13.54vw;
  }
}
.warenkorb {
  z-index: 10000;
}
.warenkorb__link {
  position: relative;
}
.warenkorb__zahl {
  font-family: "Neue Haas Unica W1G", sans-serif;
  align-items: center;
  background-color: black;
  border-radius: 1000px;
  color: white;
  display: flex;
  justify-content: center;
  position: absolute;
}

@media (max-width: 699px) {
  .warenkorb {
    display: block;
    position: absolute;
    left: 62.82vw;
    top: 13.08vw;
  }
  .body--startseite .warenkorb {
    display: none;
  }
  .body--startseite[data-menue-aktiv=true] .warenkorb {
    display: block;
  }
  .warenkorb__svg {
    width: 10.26vw;
    height: 9.49vw;
  }
  .warenkorb__zahl {
    font-size: 2.31vw;
    line-height: 6.92vw;
    font-weight: 700;
    height: 4.87vw;
    left: -1.79vw;
    min-width: 4.62vw;
    top: 2.05vw;
  }
}
@media (min-width: 700px) {
  .warenkorb {
    position: fixed;
    top: 1.98vw;
    right: 2.81vw;
  }
  .body--shop .warenkorb, .body--login .warenkorb, .body--login-entry .warenkorb, .body--benutzerprofil .warenkorb, .body--reset-password .warenkorb, .body--kontakt .warenkorb, .body--verlag .warenkorb, .body--warenkorb .warenkorb, .body--datenschutz .warenkorb, .body--impressum .warenkorb, .body--agb .warenkorb {
    top: 1.98vw;
    right: 1.3vw;
  }
  .body--startseite .warenkorb {
    left: 31.98vw;
  }
  .warenkorb__svg {
    width: 2.19vw;
    height: 3.13vw;
  }
  .warenkorb__zahl {
    font-size: 0.73vw;
    line-height: 1.88vw;
    font-weight: 700;
    height: 1.82vw;
    left: -0.78vw;
    min-width: 1.82vw;
    top: 0.21vw;
  }
}
.macro-bild {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
}
.macro-bild .lazyload,
.macro-bild .lazyloading {
  opacity: 0;
}
.macro-bild .lazyloaded {
  transition: background-color 500ms ease-in;
  opacity: 1;
  background-color: transparent;
  /*  transition is applied to lazyloaded not lazyload */
  transition: opacity 500ms ease-in;
}

.macro-bild__img,
.macro-bild__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

body[data-menue-aktiv=true] {
  position: fixed;
  width: 100%;
}
body[data-menue-aktiv=true] .main {
  pointer-events: none;
}

.macro-video {
  position: relative;
  overflow: hidden;
}
.macro-video__iframe, .macro-video iframe {
  background-color: transparent;
  border: none;
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.seite {
  position: relative;
}

@media (max-width: 699px) {
  .seite {
    width: 100%;
    height: 100%;
  }
}
.main {
  position: relative;
}

@media (max-width: 699px) {
  .main {
    padding: 30.77vw 0vw 46.92vw 0vw;
  }
  .body--startseite .main, .body--word .main, .body--word-entry .main, .body--excel .main, .body--excel-entry .main, .body--powerpoint .main, .body--powerpoint-entry .main, .body--junior .main, .body--junior-entry .main, .body--shorty .main, .body--shorty-entry .main {
    padding: 30.77vw 7.69vw 46.92vw 7.69vw;
  }
  .body--startseite .main {
    padding: 30.77vw 7.69vw 0vw 7.69vw;
  }
}
@media (min-width: 700px) {
  .main {
    padding: 1.98vw 7.55vw 7.6vw 28.91vw;
  }
  .body--startseite .main {
    padding: 0vw 1.56vw 0vw 28.91vw;
  }
}